import axios from 'axios';

import { API_ORIGIN, BASE_API_URL } from './index';

export const authorizedReq = axios.create({
  params: { json: true },
});

export const getPublicApiKey = () =>
  authorizedReq({
    url: `${BASE_API_URL}/auth/public-api-key`,
  });

export const checkoutConfig = (session_id, headers) =>
  authorizedReq({
    method: 'get',
    url: `${API_ORIGIN}/checkout/api/intl/v1/checkout-page/${session_id}`,
    headers,
  });

export const getPaymentAttemptDetails = (reference_number) =>
  axios.get(
    `${BASE_API_URL}/plugins/payment_request/transaction/details/${reference_number}`,
  );

export const getDefaultUnitDetails = () =>
  axios.get(`${BASE_API_URL}/unit/default`);
