import axios from 'axios';

export const API_ORIGIN = localStorage.getItem('VUE_APP_CORE_SERVER_ORIGIN');
export const BASE_API_URL = API_ORIGIN + '/api/v1';
export const BASE_CHECKOUT_API = API_ORIGIN + '/checkout/v1';

// Authorization header is inserted automatically to each request (if token is present)

export const Service = (url) =>
  axios.create({
    baseURL: BASE_API_URL + url,
    params: { json: true },
  });
