export default {
  props: {
    transaction_details: {},
    is_customer_payment: {
      type: Boolean,
      default: false,
    },
    attempt: {
      type: Boolean,
      default: false,
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    object2arr(obj) {
      return Object.keys(obj).map(function (key) {
        return { title: key, content: obj[key], length: obj[key].length };
      });
    },
    download(url) {
      window.open(url);
    },
    mergeNewValue(arr1, arr2) {
      arr2.forEach((el2) => {
        let found = arr1.filter((val) => {
          return val.content === el2.content;
        })[0];
        // console.log(el2);
        // let found = false;
        // arr1.forEach(el1 => {
        //     for(let val2 in el2){
        //         for(let val1 in el1){
        //             if(el1[val1] == el2[val2]){
        //                 found=true;
        //             }
        //         }
        //     }
        // });
        if (!found && el2.content !== '') {
          arr1.push(el2);
        }
      });
      return arr1;
    },
    add_transaction_details(tkey, detailArray) {
      if (!tkey.value) {
        return;
      }

      this.isCheckout
        ? detailArray.push({
            title: tkey.label,
            content: tkey.value,
            length: tkey.value.length,
          })
        : detailArray.push({
            title: this.isArabic ? tkey.label_ar : tkey.label_en,
            content: tkey.value,
            length: tkey.value.length,
          });
    },
  },
  computed: {
    isArabic() {
      return this.$i18n.locale === 'ar';
    },
    getBottomDetails() {
      const bottomDate = this.transaction_details?.bottom?.find(
        (bottom) =>
          bottom[this.isCheckout ? 'label' : 'label_en']?.includes('Date') ||
          bottom['label']?.includes('التاريخ'),
      );
      const bottomInternalId = this.transaction_details?.bottom?.find(
        (bottom) =>
          bottom[this.isCheckout ? 'label' : 'label_en']?.includes(
            'Internal ID',
          ) || bottom['label']?.includes('معرف داخلي'),
      );

      return {
        bottomDate: bottomDate || {},
        bottomInternalId: bottomInternalId || {},
      };
    },

    customerDetailAndExtraData: function () {
      //get all details as one array to loop on it and populate order description tab
      let detailArray = []; //array of {title ,content, length}

      for (const tkey of this.transaction_details.order_description || []) {
        //
        if (tkey) {
          this.add_transaction_details(tkey, detailArray);
        }
      }
      if (!this.is_customer_payment) {
        return detailArray;
      }
      let res = [];
      // this array will be sorted like this the third is always wide
      // 0    1   2
      // --- --- ------
      // 3    4   5
      // --- --- ------
      // --- --- ------
      // internal-id with date is wide and last
      let third = detailArray.filter((val) => {
        return val.length > this.breakpoint;
      }); //every long card
      let first = detailArray.filter((val) => {
        return val.length <= this.breakpoint;
      }); //every small card
      let offset = 0;
      for (let index = 0; index < first.length; index++) {
        const element = first[index];
        if ((index + offset + 1) % 3 == 0) {
          //third index:
          if (third.length > 0) {
            //if there is a wide then add it
            res.push(third.pop());
            res.push(element);
            offset++;
          } else {
            // else if no more wide then make it wide
            element.length = this.breakpoint + 1;
            res.push(element);
          }
        } else {
          res.push(element);
        }
      }
      res = res.concat(third);
      return res;
    },
  },
  data: () => ({
    breakpoint: 20,
  }),
};
